@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  background: #f2f2f2;


}

#root {
  height: 100%;
}


::-webkit-scrollbar-track {
background-color: none;
border-radius: 10px;
}

::-webkit-scrollbar-corner {
height: 0;
}

::-webkit-scrollbar {
width: 12px;
border-radius: 10px;
}

::-webkit-scrollbar-thumb {
background-color: #a5a5a5;
border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
background: #555; /* Handle color on hover */
}


/* @font-face {
  font-family: PoppinsItalic;
  src: url("./Assets/font-family/Poppins-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: PoppinsRegular;
  src: url("./Assets/font-family/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: PoppinsBold;
  src: url("./Assets/font-family/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: PoppinsMedium;
  src: url("./Assets/font-family/Poppins-Medium.ttf") format("truetype");
}  */


/* styles.css */

@font-face {
  font-family: 'Poppins-Bold';
  src: url('./assets/font-family/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('./assets/font-family/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Italic';
  src: url('./assets/font-family/Poppins-Italic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('./assets/font-family/Poppins-Medium.ttf') format('truetype');
  font-weight: medium;
}

@font-face {
  font-family: 'Poppins-Black';
  src: url('./assets/font-family/Poppins-Black.ttf') format('truetype');
  font-weight: 900; /* Adjust the weight according to your font */
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('./assets/font-family/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 700; /* Adjust the weight according to your font */
}
@font-face {
  font-family: 'Poppins-ThinItalic';
  src: url('./assets/font-family/Poppins-ThinItalic.ttf') format('truetype');
  font-weight: 600; /* Adjust the weight according to your font */
}
@font-face {
  font-family: 'Poppins-Light';
  src: url('./assets/font-family/Poppins-Light.ttf');
}
@font-face {
  font-family: 'Bebas-Neue-Regular';
  src: url('./assets/font-family/BebasNeue-Regular.ttf') format('truetype');
  font-weight: 600; /* Adjust the weight according to your font */
}



