@keyframes blink-wave {
    0%, 50%, 100% {
      box-shadow: 0 0 0 rgba(255, 0, 0, 0.558);
    }
    25%, 75% {
      box-shadow: 0 0 0 20px rgba(255, 0, 0, 0.7);
    }
  }

  .animate-blink-wave {
    animation: blink-wave 2s infinite;
  }